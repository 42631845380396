import type { Ref } from 'vue'
import type { LocationQueryValue } from 'vue-router'
import { isDeepEqual } from '@antfu/utils'
import type { SearchInput, SearchQuery, SearchResultFragment, SearchResultSortParameter } from '#graphql-operations'
import { useSearchParams } from '~/composables/searchParams'

interface FetchSearchOptions {
  take: Ref<number>
  total: Ref<number>
  isFetchingData: Ref<boolean>
  isLoading: Ref<boolean>
  resetSearch: () => void
  resetSearchQueryParams?: string[]
}

interface FetchSearchParams {
  page?: number
  loadDirection?: 'up' | 'down'
  search?: SearchInput
}

const DEFAULT_RESET_SEARCH_QUERY_PARAMS = [
  'q',
  'fvid',
]

export function useFetchSearch({
  take,
  total,
  isFetchingData,
  resetSearch,
  isLoading,
  resetSearchQueryParams = DEFAULT_RESET_SEARCH_QUERY_PARAMS,
}: FetchSearchOptions) {
  const route = useRoute()

  const items = ref<SearchResultFragment[]>([])
  const loadedPages = ref<number[]>([])

  const { searchParams } = useSearchParams()

  async function fetchSearch({ page = 1, loadDirection = 'down', search }: FetchSearchParams) {
    if (isFetchingData.value)
      return false

    isFetchingData.value = true

    const getRouteQueryOrderByDefault = (query: LocationQueryValue | LocationQueryValue[], defaultValue: SearchResultSortParameter): SearchResultSortParameter => {
      // query = [query].filter(Boolean).flat() as string[]
      // let orderBy = query.length ? query : defaultValue
      return {
        // popularityScore: SortOrder.DESC,
      }
    }

    const getQueryResults = (data: SearchQuery) => {
      return {
        entities: data.search.items,
        count: data.search.totalItems,
      }
    }

    const defaultSearchVariables = {
      take: take.value,
      skip: (page - 1) * take.value,
      sort: getRouteQueryOrderByDefault(route.query.sort, {
        // popularityScore: SortOrder.DESC,
      }),
      groupByProduct: true,
    }

    const isPriceSortActive = (sort?: string | null | (string | null)[]) => {
      if (!sort)
        return false

      const sortArray = Array.isArray(sort) ? sort : [sort]
      return sortArray.some(s => (s ?? '').includes('price'))
    }

    watch(
      () => route.query.sort,
      (newSort, oldSort) => {
        // const priceSortHasBeenActivated = isPriceSortActive(newSort) && !isPriceSortActive(oldSort)
        // if (priceSortHasBeenActivated && route.query.listed !== 'true') {
        //   route.query.listed = 'true'
        // }
      },
    )

    const searchVariables = search && Object.keys(search).length ? search : searchParams.value

    const searchInput: SearchInput = {
      ...defaultSearchVariables,
      ...searchVariables,
    }

    const { data: result } = await useGraphqlQuery('search', {
      search: searchInput,
    })

    const { entities, count } = getQueryResults(result)
    total.value = count

    if (!loadedPages.value.includes(page)) {
      if (loadDirection === 'up')
        items.value = [...entities, ...items.value]

      else
        items.value = [...items.value, ...entities]

      loadedPages.value.push(page)
    }

    isFetchingData.value = false
    isLoading.value = false
    return true
  }

  const clearFetchResults = () => {
    items.value = []
    loadedPages.value = []
  }

  const refresh = (search?: Record<string, string | number>[]) => {
    clearFetchResults()
    fetchSearch({ search })
  }

  const resetSearchQueryParamsValues = computed(() =>
    Object.fromEntries(resetSearchQueryParams.map(key => [key, route.query[key]])),
  )

  watch(resetSearchQueryParamsValues, (currentQuery, prevQuery) => {
    if (isDeepEqual(currentQuery, prevQuery))
      return

    loadedPages.value = []
    resetSearch()
  })

  return {
    items,
    fetchSearch,
    refresh,
    clearFetchResults,
  }
}
